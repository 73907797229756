<template>
  <div class="d-card" :class="{ 'd-card--big': big }" v-if="data">
    <span class="d-card__title" v-if="!big">{{ data.title }}</span>
    <ul class="d-card__contacts">
      <li v-if="data.phone">
        <a :href="`tel:${data.phone}`">
          <IconComponent category="vuesax-bold" name="call" />
          <span>{{ data.phone }}</span>
        </a>
      </li>
      <li v-if="data.email">
        <a :href="`mailto:${data.email}`">
          <IconComponent category="vuesax-bold" name="message" />
          <span>{{ data.email }}</span>
        </a>
      </li>
      <li v-if="data.link">
        <a :href="data.link">
          <IconComponent category="vuesax-bold" name="link" />
          <span>{{ data.link | url_domain }}</span>
        </a>
      </li>
      <li v-if="data.address">
        <router-link :to="{ name: 'contacts' }" @click.prevent>
          <IconComponent category="vuesax-bold" name="location" />
          <span>{{ data.address }}</span>
        </router-link>
      </li>
    </ul>
    <div class="d-card__person" v-if="data.director_title">
      <ImgComponent :img="data.director_img" width="50" height="50" class="d-card__person-image" />
      <div class="d-card__person-content">
        <span class="d-card__person-name">{{ data.director_title }}</span>
        <span class="d-card__person-title">Директор</span>
      </div>
    </div>
    <div class="d-card__footer">
      <router-link :to="{ name: 'ministry-departments-open', params: { id: data.id } }" v-if="!big">
        Об учреждении
      </router-link>
      <a :href="data.site" target="_blank" v-if="data.site">
        <span>{{ data.site | url_domain }}</span>
        <IconComponent category="vuesax-bold" name="export" />
      </a>
    </div>
  </div>
</template>

<script>
import IconComponent from "components/IconComponent.vue";
import ImgComponent from "components/ImgComponent.vue";

export default {
  name: "DepartmentCard",
  props: {
    data: Object,
    big: Boolean,
  },
  components: { ImgComponent, IconComponent },
};
</script>

<style lang="stylus">
.d-card {
  display grid
  background: var(--white);
  border: 1px solid var(--gray-dark);
  border-radius: 10px;

  &--big {
    & ^[0]__person {
      background var(--white)
      border-top 1px solid var(--gray)
      border-bottom 1px solid var(--gray)
    }
    //
    //& ^[0]__footer a:first-child {
    //  background var(--main)
    //  border-color var(--main)
    //  color var(--white)
    //}
  }

  &__title {
    font-weight: 700;
    font-size: 1.125em;
    line-height: 28px;
    color: var(--dark);
    padding 30px
    padding-bottom 0
    +below(860px) {
      padding 15px
      padding-bottom 0
    }
  }

  &__contacts {
    display flex
    flex-wrap wrap
    gap 15px
    padding 30px
    +below(860px) {
      padding 15px
    }

    li {
      display inline-flex

      a {
        font-weight: 500;
        font-size: 1.125em;
        line-height: 28px;
        color: var(--dark);
        display flex
        align-items center
        gap 5px

        .icon {
          width 40px
          height 40px
          border-radius 100%
          background var(--gray)
          display inline-flex
          align-items center
          justify-content center

          svg {
            width 18px
            height: 18px;

            path {
              fill var(--dark)
            }
          }
        }
      }
    }
  }

  &__person {
    background var(--gray)
    padding 15px 30px
    display flex
    align-items center
    gap 15px
  }

  &__person-image {
    width 60px
    height: 60px;
    border 3px solid var(--gray-dark)
    border-radius 100%
  }

  &__person-content {
    display grid
  }

  &__person-name {
    font-weight: 500;
    font-size: 1.125em;
    line-height: 28px;
    color: var(--dark);
  }

  &__person-title {
    font-weight: 500;
    font-size: 0.875em;
    line-height: 22px;
    color: var(--dark);
    opacity: 0.5;
  }

  &__footer {
    padding 30px
    display grid
    grid-template-columns 1fr 1fr
    grid-gap 30px
    +below(860px) {
      padding 15px
    }
    +below(490px) {
      grid-template-columns 1fr
      grid-gap 15px
    }

    a {
      padding 12px
      background: var(--gray)
      border: 1px solid var(--gray-dark);
      border-radius: 10px;
      font-weight: 400;
      font-size: 1em;
      line-height: 26px;
      color: var(--dark);
      display inline-flex
      justify-content center
      gap 10px
      align-items center

      .icon {
        width 16px
        height: 16px;

        svg path {
          fill var(--dark-light)
        }
      }
    }
  }
}
</style>

<template>
  <label
    :class="{
      'check--focus': focus,
      'check--disabled': disabled,
      'check--error': error,
      'check--checked': checked && !indeterminate,
      'check--indeterm': indeterminate,
    }"
    class="check"
  >
    <input
      :checked="checked"
      :disabled="disabled"
      @focus="focus = true"
      @blur="focus = false"
      type="checkbox"
      @change="$emit('change', $event.target.checked)"
    />
    <span class="check__state">
      <IconComponent v-if="state" category="default" :name="state" />
    </span>
    <slot />
  </label>
</template>

<script>
import IconComponent from "components/IconComponent.vue";

export default {
  name: "CheckboxComponent",
  model: {
    prop: "checked",
    event: "change",
  },
  props: {
    error: Boolean,
    checked: Boolean,
    disabled: Boolean,
    indeterminate: Boolean,
  },
  data() {
    return {
      focus: false,
    };
  },
  computed: {
    state() {
      if (this.indeterminate) {
        return "indeterm";
      }
      return "check";
    },
  },
  components: { IconComponent },
};
</script>

<style lang="stylus">
.check {
  display flex
  gap 8px
  position relative
  cursor pointer
  align-items: center;
  text-align: left;
  user-select none

  input {
    absolute left top
    width 0
    height 0
    overflow hidden
    appearance none
    outline none
  }

  &--disabled {
    color var(--gray-dark)
  }

  &--error {
    color var(--red)
  }

  &__state {
    width 15px
    height 15px
    background: var(--white);
    border: 1px solid var(--gray-dark);
    box-sizing: border-box;
    border-radius: 3px;
    display flex
    align-items center
    justify-content center

    ^[0]--focus & {
      background: var(--white);
      border: 1px solid var(--main);
      border-radius: 3px;
      box-shadow: 0 0 0 2px var(--main-o15);
    }

    ^[0]--indeterminate &,
    ^[0]--checked & {
      background var(--main)
      border-color var(--main)

      .icon svg path {
        stroke var(--white)
      }
    }

    ^[0]--disabled & {
      background: var(--gray-dark);
      border: 1px solid var(--gray-dark);

      .icon svg path {
        stroke var(--white)
      }
    }

    ^[0]--error & {
      border-color var(--red)
    }

    .icon {
      width 15px
      height 15px
      display flex
      align-items center
      justify-content center

      svg path {
        stroke transparent
      }
    }
  }
}
</style>

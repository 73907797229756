<template>
  <section class="ministry-departments">
    <h2 class="ministry-departments__title">
      {{ departmentTitle }} осуществляет координацию и контроль деятельности следующих подведомственных
      учреждений:
    </h2>
    <div class="ministry-departments__filter">
      <InputComponent
        v-model.trim="form.title.value"
        autofocus
        placeholder="Введите название"
        @input="debounceFilter"
      >
        <template v-slot:action>
          <IconComponent category="vuesax-bold" name="search-normal" />
        </template>
      </InputComponent>
      <SelectComponent
        v-model="form.category.value"
        :errors="form.category.errors"
        :options="ministry_category"
        placeholder="Все"
        multiple
        label-name="title"
        @input="filter"
      />
    </div>
    <div class="ministry-departments__list">
      <DepartmentCard v-for="(department, i) in departments" :key="i" :data="department" />
    </div>
  </section>
</template>

<script>
import IconComponent from "components/IconComponent.vue";
import InputComponent from "components/inputs/InputComponent.vue";
import SelectComponent from "components/inputs/select/index.vue";
import DepartmentCard from "components/DepartmentCard.vue";
import DEPARTMENTS from "gql/queries/sub_departments.graphql";

export default {
  name: "MinistryDepartmentsPage",
  async asyncData({ apollo, store }) {
    await apollo.defaultClient
      .query({
        query: DEPARTMENTS,
        variables: {},
      })
      .then(({ data }) => {
        store.dispatch("ministry/save", data);
      })
      .catch(() => {});
  },
  computed: {
    system_type() {
      return this.$store.state.department.system_type;
    },
    departmentTitle() {
      if (this.system_type) {
        return this.system_type.name;
      }
      return "Министерство";
    },
    ministry_category() {
      return this.$store.state.ministry.subdepartment_categories;
    },
    departments() {
      return this.$store.state.ministry.sub_departments;
    },
  },
  data() {
    return {
      form: {
        title: {
          value: null,
          defaultValue: null,
          errors: [],
        },
        category: {
          value: [],
          defaultValue: [],
          errors: [],
        },
      },
      debounceInterval: null,
    };
  },
  methods: {
    fetch() {
      let category = this.form.category.value.map((item) => item.id);
      let variables = {
        title: this.form.title.value,
        category: category,
      };
      this.$apollo
        .query({
          query: DEPARTMENTS,
          variables,
        })
        .then(({ data }) => {
          this.$store.dispatch("ministry/save", data);
        })
        .catch(() => {});
    },
    filter() {
      this.fetch();
    },
    debounceFilter() {
      clearInterval(this.debounceInterval);
      this.debounceInterval = setTimeout(this.filter, 500);
    },
  },
  metaInfo: {
    title: "Подведомственные учреждения",
  },
  components: { DepartmentCard, InputComponent, SelectComponent, IconComponent },
};
</script>

<style lang="stylus">
.ministry-departments {
  display grid
  grid-gap 50px
  +below(860px) {
    grid-gap 30px
  }

  &__title {
    font-weight: 700;
    font-size: 1.75em;
    line-height: 38px;
    color: var(--dark);
    margin 0
    +below(860px) {
      font-size: 1.125em;
      line-height: 28px;
    }
  }
  &__filter {
    padding 30px
    border 1px solid var(--gray-dark)
    background-color var(--white)
    border-radius 10px
    display grid
    grid-gap 30px
    grid-template-columns repeat(2, 1fr)
    +below(730px) {
      grid-template-columns repeat(1, 1fr)
    }
  }

  &__list {
    display grid
    grid-template-columns repeat(2, 1fr)
    grid-gap 30px
    +below(860px) {
      grid-gap 15px
      grid-template-columns 1fr
    }
  }
}
</style>
